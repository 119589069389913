<template>
  <div>
    <div>
        <p>Do you really want to delete this Explorer?</p>
        <p>This will disconnect this Explorer from Degust and can not be reverted from within the Degust Cockpit.</p>
        <p>If you have physical access to the Explorer's SD card, you may reactivate the Raspberry Pi to be a new Explorer at any time.</p>
        <p>If you understand the consequences, you may now delete the Explorer.</p>
    </div>
  </div>
</template>
<script>
export default {
  data: function () {
    return {};
  },
  props: ["row", "currentTable", "result"],
};
</script>